<template>
  <div class="flex flex-1 w-full">
    <common-detail-table
      :bredCrumbs="customBreadCrumbs"
      :columnsProps="columns"
      :credit-pack-id="creditPackId"
      :data-fetching-url="dataFetchingUrl"
      :back-redirection-url="backRedirectionUrl"
      data-fetching-key="credit_packs_usage"
      show-query-icon
      show-back-button
    />
  </div>
</template>

<script>
import CommonDetailTable from "../common-detail-table.vue";

export default {
  name: "CreditUsage",
  components: {
    CommonDetailTable,
  },

  data: () => {
    return {
      customBreadCrumbs: [
        { name: "Admin" },
        { name: "Credit Pack" },
        { name: "Inventory" },
      ],
      columns: [
        {
          label: "Unique Id",
          field: "unique_id",
          sortable: true,
          config: {
            filter: true,
            alignCenter: true,
          },
          tdClass: "truncate max-w-xs",
        },
        {
          label: "Analyst",
          field: "analyst",
          sortable: true,
          config: {
            filter: true,
            alignCenter: true,
          },
          tdClass: "truncate max-w-xs",
        },
        {
          label: "Date",
          field: "date",
          field_type: "date",
          sortable: true,
          config: {
            filter: true,
            filter_type: "date",
            type: "showDate",
            query_keys: {
              start: "usage_date_from",
              end: "usage_date_to",
            },
            customClass: "flex justify-center items-center",
          },
          tdClass: "truncate max-w-xs",
        },
        {
          label: "Case Id",
          field: "case_id",
          sortable: true,
          config: {
            filter: true,
            alignCenter: true,
          },
          tdClass: "truncate max-w-xs",
        },
        {
          label: "Source",
          field: "source",
          sortable: true,
          config: {
            filter: true,
            alignCenter: true,
          },
          tdClass: "truncate max-w-xs",
        },
        {
          label: "Credit Used",
          field: "credit_used",
          sortable: true,
          config: {
            filter: true,
            alignCenter: true,
          },
          tdClass: "truncate max-w-xs",
        },
        // {
        //   label: "Service Charge",
        //   field: "service_charge",
        //   sortable: true,
        //   config: {
        //     filter: true,
        //     alignCenter: true,
        //   },
        //   tdClass: "truncate max-w-xs",
        // },
        // {
        //   label: "Query",
        //   field: "query",
        //   sortable: true,
        //   config: {
        //     filter: false,
        //     type: "svg_icon",
        //   },
        //   tdClass: "truncate max-w-xs",
        // },
      ],
      creditPackId: "",
      dataFetchingUrl: "/credit-pack-inventory/credit_pack_usage",
      backRedirectionUrl: "/credit-packs?tab=inventory",
      // rows: [
      //   {
      //     analyst: "string",
      //     date: "2023-08-04T06:59:38.292Z",
      //     case_id: "string",
      //     source: "string",
      //     credit_used: 0,
      //     service_charge: 0,
      //     query: "string",
      //   },
      // ],
      // queryIcon,
    };
  },
  created() {
    this.creditPackId = this.$route.params.id;
    this.customBreadCrumbs.push({ name: this.creditPackId });
  },
};
</script>

<style scoped lang="scss">
@import "@shared/assets/mixins/mixins.scss";
@include tableComponent;
</style>
