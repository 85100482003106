<template>
  <div class="flex-1 flex overflow-y-auto p-4 -m-4">
    <tabs @select="selectTab" :selected="tabs.selected" :tabs="tabs.list">
      <template #content-creditAllotted>
        <credit-allotted />
      </template>
      <template #content-creditUsage>
        <credit-usage />
      </template>
    </tabs>
  </div>
</template>

<script>
import tabs from "@/components/dashboard-components/tabs";
import creditAllotted from "./credit-allotted.vue";
import creditUsage from "./credit-usage.vue";

export default {
  name: "field-tab",
  components: {
    tabs,
    creditAllotted,
    creditUsage,
  },
  props: {},
  data() {
    return {
      tabs: {
        selected: "creditAllotted",
        list: [
          {
            id: "creditAllotted",
            label: "Credit Allotted",
          },
          {
            id: "creditUsage",
            label: "Credit Usage",
          },
        ],
      },
    };
  },
  computed: {},
  mounted() {
    this.tabs.selected = this.$route?.query?.tab || "creditAllotted";
  },
  methods: {
    selectTab(id) {
      this.tabs.selected = id;
      this.$router.push({
        path: this.$route.path,
        query: {
          tab: this.tabs.selected,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
