<template>
  <div class="flex flex-1 w-full">
    <common-detail-table
      :bredCrumbs="customBreadCrumbs"
      :columnsProps="columns"
      :credit-pack-id="creditPackId"
      :data-fetching-url="dataFetchingUrl"
      :back-redirection-url="backRedirectionUrl"
      data-fetching-key="credit_packs_alloted"
      show-back-button
      showCreditAllotedStatus
    />
  </div>
</template>

<script>
import CommonDetailTable from "../common-detail-table.vue";

export default {
  name: "CreditAllocated",
  components: {
    CommonDetailTable,
  },

  data: () => {
    return {
      customBreadCrumbs: [
        { name: "Admin" },
        { name: "Credit Pack" },
        { name: "Inventory" },
      ],
      columns: [
        {
          label: "Unique Id",
          field: "unique_id",
          sortable: true,
          config: {
            filter: true,
            alignCenter: true,
          },
        },
        {
          label: "Assignee",
          field: "assignee",
          sortable: true,
          config: {
            filter: true,
            alignCenter: true,
          },
          tdClass: "truncate max-w-xs",
        },
        {
          label: "Limit/Month",
          field: "limits_per_month",
          sortable: true,
          config: {
            filter: true,
            alignCenter: true,
          },
          tdClass: "truncate max-w-xs",
        },
        {
          label: "Allocated",
          field: "alloted",
          sortable: true,
          config: {
            filter: true,
            alignCenter: true,
          },
          tdClass: "truncate max-w-xs",
        },
        {
          label: "Used",
          field: "used",
          sortable: true,
          config: {
            filter: true,
            alignCenter: true,
          },
          tdClass: "truncate max-w-xs",
        },
        {
          label: "Balance",
          field: "balance",
          sortable: true,
          config: {
            filter: true,
            alignCenter: true,
          },
          tdClass: "truncate max-w-xs",
        },
        {
          label: "Expiry Date",
          field: "expiry_date",
          field_type: "date",
          sortable: true,
          config: {
            filter: true,
            filter_type: "date",
            type: "showDate",
            query_keys: {
              start: "expiry_date_from",
              end: "expiry_date_to",
            },
            customClass: "flex justify-center items-center",
          },
          tdClass: "truncate max-w-xs",
        },
        {
          label: "Status",
          field: "status",
          sortable: true,
          config: {
            filter: true,
            type: "status_text",
          },
          tdClass: "truncate max-w-xs",
        },
      ],
      creditPackId: "",
      dataFetchingUrl: "/credit-pack-inventory/credit_pack_alloted",
      backRedirectionUrl: "/credit-packs?tab=inventory",
    };
  },
  created() {
    this.creditPackId = this.$route.params.id;
    this.customBreadCrumbs.push({ name: this.creditPackId });
  },
};
</script>

<style scoped lang="scss">
@import "@shared/assets/mixins/mixins.scss";
@include tableComponent;
</style>
